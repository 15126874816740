<template>
  <div id="app">
    <CienciaIntro />
  </div>
</template>

<script>
import CienciaIntro from './views/intro.vue';

export default {
  name: 'App',
  components: {
    CienciaIntro
  }
}
</script>

<style>
/* Tus estilos aquí */
</style>